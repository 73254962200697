import React, { FC, useContext, useEffect, useState } from "react";
import StakeTab from "../Components/StakeTab/StakeTab";
import UnstakeTab from "../Components/UnstakeTab/UnstakeTab";
import Harvest from "../Components/Harvest/Harvest";
import { Web3Context } from "../Components/Web3Context/Web3Context";
import MiniPrice from "../Components/CardControls/MiniPrice";
import iconLock from "../Images/lock.png";
import iconPieChart from "../Images/pie-chart.png";
import { roundAndFormatNumber } from "../Common/HelperFunctions";

interface StakeCardProps {
    className?: string;
    featured?: boolean;
    amountElementId: string;
    title: string;
    phubrewardpooladdress: string;
    earnedSymbolAddress: string;
}

const StakeCard: FC<StakeCardProps> = (props) => {
    const {
        web3Connected,
        walletAddress,
        exitPhubrewardPool,
        fetchPhubStakedBalance,
        getTokenPriceFromPancakeswapInDollars,
        fetchPhubApr,
        fetchPhubTvl,
    } = useContext(Web3Context);
    const [stakedBalance, setStakedBalance] = useState<number>(0);
    const [activeTab, setActiveTab] = useState<number>(0);
    const [apr, setApr] = useState<number>(0);
    const [tvl, setTvl] = useState<number>(0);

    const phubTokenAddress = "0x95A6772a2272b9822D4b3DfeEaedF732F1D28DB8";

    useEffect(() => {
        if (web3Connected) {
            fetchStakedBalance();
        }
        fetchStats();

        const interval = setInterval(async () => {
            if (web3Connected) {
                fetchStakedBalance();
            }
        }, 10000);

        const statsInterval = setInterval(async () => {
            fetchStats();
        }, 60000);

        return () => {
            clearInterval(interval);
            clearInterval(statsInterval);
        };
    }, [web3Connected, walletAddress]);

    async function fetchStakedBalance() {
        const balance = await fetchPhubStakedBalance(props.phubrewardpooladdress);
        setStakedBalance(balance);
    }

    async function fetchStats() {
        const tvl = await fetchPhubTvl(props.phubrewardpooladdress);
        const phubPrice = await getTokenPriceFromPancakeswapInDollars(phubTokenAddress);
        const apr = await fetchPhubApr(props.phubrewardpooladdress, phubPrice, tvl * phubPrice);

        setApr(apr);
        setTvl(tvl * phubPrice);
    }

    return (
        <div>
            <div
                className={
                    "py-6 overflow-hidden bg-white card relative shadow rounded-xl text-left text-gray-800 dark:text-light mx-auto" +
                    (props.featured ? " p-0.5 bg-gradient-to-tr from-yellow-500 via-red-500 to-indigo-700 " : " bg-white dark:bg-dark2-500") +
                    (props.className ? ` ${props.className}` : "")
                }
            >
                <div>
                    <div
                        className="font-extrabold px-8 text-2xl items-center gap-2 font-urbanist mb-4 whitespace-nowrap dark:text-light">
                        {props.title}
                    </div>
                    <div className="flex justify-evenly w-full gap-4 mb-4 flex-wrap">
                        <MiniPrice
                            icon={iconPieChart}
                            iconAlt="pie chart"
                            title="APY"
                            value={roundAndFormatNumber(apr * 100, 2) + "%"}
                        />
                        <MiniPrice
                            icon={iconLock}
                            iconAlt="lock"
                            title="TVL"
                            value={"$" + roundAndFormatNumber(tvl, 2, true)}
                            right
                        />
                    </div>
                    <div
                        className="text-black-500 text-left text-2xl sm:text-3xl md:text-4xl font-semibold mb-0 text-center">
                        <span className="text-primary-500">//</span></div>
                    {/*body*/}
                    <div className="border border-double border-white rounded overflow-hidden mx-5 mt-5 pb-5">
                        <div className="px-4 w-full bg-gray -mt-5">
                            <div className="columns-2 w-full text-center bg-gray py-1 mt-5 rounded">
                                <div className={"py-4 cursor-pointer " + (activeTab == 0 ? 'bg-dark4-500' : '')}
                                     onClick={() => setActiveTab(0)}>
                                    Stake
                                </div>
                                <div className={"py-4 cursor-pointer " + (activeTab == 1 ? 'bg-dark4-500' : '')}
                                     onClick={() => setActiveTab(1)}>
                                    Unstake
                                </div>
                            </div>
                        </div>
                        {activeTab == 0 && (
                            <div className="text-left px-4 pt-5">
                                <StakeTab amountElementId={props.amountElementId}
                                          phubrewardpooladdress={props.phubrewardpooladdress} />
                            </div>
                        )}
                        {activeTab == 1 && (
                            <div className="text-left px-4 pt-5">
                                <UnstakeTab amountElementId={props.amountElementId}
                                            phubrewardpooladdress={props.phubrewardpooladdress} />
                            </div>
                        )}
                    </div>
                    {stakedBalance > 0 && (
                        <>
                            <div
                                className="text-black-500 text-left text-2xl sm:text-3xl md:text-4xl font-semibold mb-0 mt-5 text-center">
                                <span className="text-primary-500">//</span>
                            </div>
                            <div className="mx-9 mt-5 pb-5 flex flex-col items-center">
                                <Harvest amountElementId={props.amountElementId}
                                         phubrewardpooladdress={props.phubrewardpooladdress}
                                         earnedSymbolAddress={props.earnedSymbolAddress} />
                            </div>
                        </>
                    )}
                </div>
            </div>
            {stakedBalance > 0 && (
                <div
                    className="flex items-center justify-end pt-4 border-solid border-slate-200 rounded-b w-60 mx-auto">
                    <>
                        <button
                            className="flex-1 rounded-full border border-solid border-white text-white uppercase px-5 py-2 font-semibold drop-shadow-[0px_4px_5px_rgba(0,0,0,0.25)] whitespace-nowrap"
                            onClick={() => {
                                exitPhubrewardPool(props.phubrewardpooladdress);
                            }}
                        >
                            Claim & Withdraw
                        </button>
                    </>
                </div>
            )}
        </div>
    );
};

export default StakeCard;
