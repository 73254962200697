import React, { FC, useContext, useEffect, useState } from "react";
import MiniEditablePrice from "../CardControls/MiniEditablePrice";
import { roundAndFormatNumber } from "../../Common/HelperFunctions";
import { Web3Context } from "../Web3Context/Web3Context";
import ConnectButton from "../ConnectButton/ConnectButton";

interface StakeCardProps {
    className?: string;
    amountElementId: string;
    phubrewardpooladdress: string;
}

const UnstakeTab: FC<StakeCardProps> = (props) => {
    const {
        web3Connected,
        connectWeb3,
        disconnectWeb3,
        walletAddress,
        fetchPhubStakedBalance,
        unstakePhubrewardPool,
    } = useContext(Web3Context);
    const phubTokenAddress = "0x95a6772a2272b9822d4b3dfeeaedf732f1d28db8";

    const [stakedBalance, setStakedBalance] = useState<number>(0);
    const [unstakeAmount, setUnstakeAmount] = useState<number>(0);

    useEffect(() => {
        if (web3Connected) {
            fetchStakedBalance();
        }

        const interval = setInterval(async () => {
            if (web3Connected) {
                fetchStakedBalance();
            }
        }, 10000);

        return () => {
            clearInterval(interval);
        };
    }, [web3Connected, walletAddress]);

    async function updateStakeAmount(elem: HTMLInputElement) {
        console.log(`Element: ${elem.id}   Value: ${elem.value}`);
        setUnstakeAmount(elem.value === "" ? 0 : elem.valueAsNumber);
    }

    async function fetchStakedBalance() {
        const balance = await fetchPhubStakedBalance(props.phubrewardpooladdress);
        setStakedBalance(balance);
    }

    return (
        <>
            <div className="pb-6 pl-2"><h1 className="text-xl">Unstake</h1></div>
            <div className="flex justify-evenly w-full gap-4 flex-wrap relative">
                <div
                    className="text-xs text-slate-400 self-center cursor-pointer mr-2 absolute right-20 z-50"
                    onClick={() => {
                        const elem = document.getElementById(props.amountElementId + '_unstake') as HTMLInputElement;
                        // If fromToken is BNB then subtract a few digits to make sure there is enough to cover the gas
                        elem.value = String(stakedBalance);
                        updateStakeAmount(elem);
                    }}
                >
                    MAX
                </div>
                <div
                    className="text-xs text-slate-400 self-center cursor-pointer mr-2 absolute right-0 -top-5 z-50"
                    onClick={() => {
                        const elem = document.getElementById(props.amountElementId + '_unstake') as HTMLInputElement;
                        // If fromToken is BNB then subtract a few digits to make sure there is enough to cover the gas
                        elem.value = String(stakedBalance);
                        updateStakeAmount(elem);
                    }}
                >
                    Balance: {roundAndFormatNumber(stakedBalance, 8)}
                </div>
                <MiniEditablePrice
                    icon={"https://swap.peghub.com/images/tokens/0x95A6772a2272b9822D4b3DfeEaedF732F1D28DB8.png"}
                    type="number"
                    iconAlt={'PHUB'}
                    title={`Stake Amount`}
                    value={unstakeAmount}
                    elementId={props.amountElementId + '_unstake'}
                    onChange={(val) => updateStakeAmount(val.currentTarget)}
                    label={'PHUB'}
                />
            </div>
            <div className="mt-4">
                {!web3Connected ? (
                    <ConnectButton
                        className=""
                        connectWeb3={connectWeb3}
                        disconnectWeb3={disconnectWeb3}
                        web3Connected={web3Connected}
                        walletAddress={walletAddress}
                    />
                ) : (
                    <div className="flex items-center justify-end border-solid border-slate-200 rounded-b">
                        <>
                            <button
                                className="flex-1 rounded-full border border-solid border-white text-white uppercase px-5 py-2 font-semibold drop-shadow-[0px_4px_5px_rgba(0,0,0,0.25)] whitespace-nowrap"
                                onClick={() => {
                                    if (unstakeAmount < 0) {
                                        alert('Minimum purchase amount is 0');
                                        return;
                                    }

                                    unstakePhubrewardPool(props.phubrewardpooladdress, unstakeAmount);
                                }}
                            >
                                Un-Stake
                            </button>
                        </>
                    </div>
                )}
            </div>
        </>
    );
};

export default UnstakeTab;
