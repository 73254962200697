import React, { useContext, useEffect, useState } from "react";
import { Web3Context } from "../Components/Web3Context/Web3Context";
import SocialBar from "../Components/Social/SocialBar";
import { useLocation } from 'react-router-dom';
import { roundAndFormatNumber } from "../Common/HelperFunctions";
import StakeCard from "../Cards/StakeCard";

function HomePage() {
    const { fetchPhubPresaleData, getTokenPriceFromPancakeswapInDollars, fetchPhubBoughtBack } = useContext(Web3Context);
    const search = useLocation().search;

    const [presaleData, setPresaleData] = React.useState<any>();
    const [phubDollarPrice, setPhubDollarPrice] = useState<number>(0);
    const [phubBoughtBack, setPhubBoughtBack] = useState<number>(0);

    const phubTokenAddress = "0x95A6772a2272b9822D4b3DfeEaedF732F1D28DB8";

    useEffect(() => {
        const vconsole = new URLSearchParams(search).get("vconsole");
        if (vconsole === 'yes') {
            const VConsole = require("vconsole");
            const vConsole = new VConsole({ theme: 'dark' });
        }

        fetchPhubPresaleDataAttr();
        fetchPhubBoughtBackAttr();
    }, []);

    const fetchPhubPresaleDataAttr = async () => {
        const data = await fetchPhubPresaleData();
        setPresaleData(data);

        const phubDollarPrice = await getTokenPriceFromPancakeswapInDollars(phubTokenAddress);
        setPhubDollarPrice(phubDollarPrice);
    };

    const fetchPhubBoughtBackAttr = async () => {
        const data = await fetchPhubBoughtBack();

        setPhubBoughtBack(data);
    };

    return (
        <>
            <main className="relative flex flex-col flex-1 w-100 focus:outline-none dark:text-light">
                <div>
                    <img src={"https://assets.peghub.com/logo.png"} className="mx-auto w-96" />
                </div>
                <h1 className="mb-4 text-2xl font-semibold text-left text-center text-black-500 sm:text-3xl md:text-4xl">
                    Welcome to {" "}
                    <span className="italic font-extrabold whitespace-nowrap">
                        <span className="text-primary-500">//</span> Peghub{" "}
                        <span className="text-primary-500">//</span>
                    </span>
                </h1>

                <div className="text-center">
                    {/* <div className="inline-block w-64 mb-5 md:mr-10 md:mb-0 rounded-xl bg-dark2-500">
                        <div>Presale Starting Amount</div>
                        <div>{presaleData && presaleData.totalTokens}</div>
                    </div> */}
                    {/* <div className="inline-block w-64 mb-5 md:mr-10 md:mb-0 rounded-xl bg-dark2-500">
                        <div>Currently Available</div>
                        <div>{presaleData && presaleData.availableTokens}</div>
                    </div> */}
                    <div className="inline-block w-64 mb-5 md:mr-10 md:mb-0 rounded-xl bg-dark2-500">
                        <div>PHUB Bought back</div>
                        {/* <div>{presaleData && presaleData.soldTokens}</div> */}
                         <div>{roundAndFormatNumber(phubBoughtBack, 3)}</div>
                    </div>
                    <div className="inline-block w-64 mb-5 md:mb-0 rounded-xl bg-dark2-500">
                        <div>PHUB Market Price</div>
                        <div>${roundAndFormatNumber(phubDollarPrice, 2, true)}</div>
                    </div>
                </div>
                <p>&nbsp;</p>
                <div className="mb-6 font-bold text-center">
                    <div>Read our whitepaper <a href="https://assets.peghub.com/peghub-tokenomics-v2.pdf"
                                                style={{ 'textDecoration': 'underline' }}>here</a>.</div>

                    <div className="mt-5 text-center">
                        <button
                            className="flex-1 rounded-full bg-gradient-to-b from-[#f51746] to-[#f51746] text-black uppercase px-5 py-2 font-semibold drop-shadow-[0px_4px_5px_rgba(0,0,0,0.25)] hover:bg-[#fee22e] focus:outline-none focus:ring focus:ring-[#fee22e] focus:ring-opacity-75 whitespace-nowrap">
                            <a target="_blank" href="https://swap.peghub.com/swap?inputCurrency=0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c&outputCurrency=0x95A6772a2272b9822D4b3DfeEaedF732F1D28DB8">Buy
                                PHUB</a>
                        </button>
                    </div>
                </div>
                <div className="flex-1 mx-auto mt-2 rounded-lg md:mx-0 contents md:block">
                    <div
                        className="grid grid-cols-1 gap-8 mx-0 mx-auto md:grid-cols-1 lg:grid-cols-2">
                        <StakeCard
                            title="Stake PHUB and earn PHUB"
                            amountElementId={"stake_phub_earn_phub"}
                            phubrewardpooladdress="0x5a4C619B62398Ba13275c5b48a40DcaFC49B1420"
                            earnedSymbolAddress="0x95A6772a2272b9822D4b3DfeEaedF732F1D28DB8"
                        />
                        <StakeCard
                            title="Stake PHUB and earn BTCB"
                            amountElementId={"stake_phub_earn_btcb"}
                            phubrewardpooladdress="0xD15fcc27A7F08f90D8A2c14d80dDDc1d5c8Cbe6D"
                            earnedSymbolAddress="0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
                        />
                    </div>
                </div>
                <SocialBar className="mt-12" />
            </main>
        </>
    );
}

export default HomePage;
