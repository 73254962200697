import React, { FC, useContext } from "react";
import ConnectButton from "../Components/ConnectButton/ConnectButton";
import { Web3Context } from "../Components/Web3Context/Web3Context";

interface HeaderProps {

}

const Header: FC<HeaderProps> = (props) => {
    const { connectWeb3, disconnectWeb3, web3Connected, walletAddress } = useContext(Web3Context);

    return (
        <div className="mb-8 relative">
            <div className="text-white text-center">
                <a href="/" className="hover:bg-gray p-3 rounded-lg" style={{'marginRight': '15px'}}>Home</a>
                <a href="https://app.bitbomb.io/" className="hover:bg-gray p-3 rounded-lg" style={{'marginRight': '15px'}}>bitBOMB</a>
                <a href="https://www.czpegs.com/" className="hover:bg-gray p-3 rounded-lg" style={{'marginRight': '15px'}}>czPegs</a>
                <a href="https://swap.peghub.com/swap" className="hover:bg-gray p-3 rounded-lg" style={{'marginRight': '15px'}}>Swap</a>
                <a href="https://vaults.peghub.com/" className="hover:bg-gray p-3 rounded-lg" style={{'marginRight': '15px'}}>Vaults</a>
                <a href="https://docs.peghub.com/" className="hover:bg-gray p-3 rounded-lg">Docs</a>
            </div>

            <div className="w-48 lg:absolute mx-auto mt-5 lg:-mt-1 right-5 top-0">
                <ConnectButton
                    className=""
                    connectWeb3={connectWeb3}
                    disconnectWeb3={disconnectWeb3}
                    web3Connected={web3Connected}
                    walletAddress={walletAddress}
                />
            </div>
        </div>
    );
}

export default Header;