import { FC } from "react";

interface MiniPriceProps {
    className?: string;
    title: string;
    value: string;
    icon?: string;
    iconAlt?: string;
    right?: boolean;
    tooltip?: string;
    textColor?: string
    maxOnClick?: () => void;
}

const MiniPrice: FC<MiniPriceProps> = (props) => {
    return (
        <div
            className={
                "flex-1 flex rounded-lg w-full items-center bg-white px-2 py-1 font-urbanist dark:from-[#31333c] dark:to-[#5a5d6b] dark:text-light" +
                (props.right
                    ? " drop-shadow-[-3px_0px_4px_rgba(0,0,0,0.15)] dark:bg-gradient-to-l"
                    : " drop-shadow-[3px_0px_4px_rgba(0,0,0,0.15)] dark:bg-gradient-to-r") +
                (props.className ? ` ${props.className}` : "")
            }
        >
            <div className={"flex flex-col flex-1" + (props.right ? " text-right order-2 ml-2" : " mr-2")}>
                <div className={"text-xs italic font-bold uppercase"} style={props.textColor ? {'color': props.textColor} : {}}>{props.title}</div>
                <div className="text-sm font-bold" style={props.textColor ? {'color': props.textColor} : {}}>{props.value}</div>
            </div>
            <div className={"shrink-0 flex"}>
                {props.maxOnClick && (
                    <div
                        className={
                            "text-xs text-slate-400 uppercase self-center cursor-pointer" +
                            (props.right ? " text-right order-2 ml-2" : " mr-2")
                        }
                        onClick={props.maxOnClick}
                    >
                        MAX
                    </div>
                )}

                { props.icon && (
                    <img src={props.icon} alt={props.iconAlt} className="h-5" />
                )}
            </div>
        </div>
    );
};

export default MiniPrice;
