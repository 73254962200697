import React, { FC, useContext, useEffect, useState } from "react";
import MiniEditablePrice from "../CardControls/MiniEditablePrice";
import { roundAndFormatNumber } from "../../Common/HelperFunctions";
import { Web3Context } from "../Web3Context/Web3Context";
import ConnectButton from "../ConnectButton/ConnectButton";
import RadialGraph from "../CardControls/RadialGraph";

interface StakeCardProps {
    className?: string;
    amountElementId: string;
    phubrewardpooladdress: string;
    earnedSymbolAddress: string;
}

const Harvest: FC<StakeCardProps> = (props) => {
    const {
        web3Connected,
        connectWeb3,
        disconnectWeb3,
        walletAddress,
        getTokenPriceFromPancakeswapInDollars,
        fetchPhubEarnedRewards,
        getRewardPhubrewardPool,
    } = useContext(Web3Context);
    const phubTokenAddress = "0x95a6772a2272b9822d4b3dfeeaedf732f1d28db8";

    const [earnedRewards, setEarnedRewards] = useState<number>(0);
    const [earnedRewardsDollars, setEarnedRewardsDollars] = useState<number>(0);

    useEffect(() => {
        if (web3Connected) {
            fetchRewards();
        }

        const interval = setInterval(async () => {
            if (web3Connected) {
                fetchRewards();
            }
        }, 10000);

        return () => {
            clearInterval(interval);
        };
    }, [web3Connected, walletAddress]);

    async function fetchRewards() {
        const balance = await fetchPhubEarnedRewards(props.phubrewardpooladdress);
        setEarnedRewards(balance);

        const price = await getTokenPriceFromPancakeswapInDollars(props.earnedSymbolAddress);
        setEarnedRewardsDollars(balance * price);
    }

    return (
        <>
            <div className="flex justify-evenly w-full gap-4 flex-wrap relative text-lg z-50">
                Rewards
            </div>
            <div className="relative -mt-4">
                <RadialGraph imageUrl={'https://swap.peghub.com/images/tokens/' + props.earnedSymbolAddress + '.png'} value={100} hideRadial />
            </div>
            <div className="flex justify-evenly w-full gap-4 flex-wrap relative text-lg">
                {roundAndFormatNumber(earnedRewards, 7, true)}
            </div>
            <div className="flex justify-evenly w-full gap-4 flex-wrap relative text-primary-500">
                ≈ ${roundAndFormatNumber(earnedRewardsDollars, 2, true)}
            </div>

            {!web3Connected ? (
                <ConnectButton
                    className=""
                    connectWeb3={connectWeb3}
                    disconnectWeb3={disconnectWeb3}
                    web3Connected={web3Connected}
                    walletAddress={walletAddress}
                />
            ) : (
                <div className="flex items-center justify-end pt-4 border-solid border-slate-200 rounded-b w-full">
                    <>
                        <button
                            className="flex-1 rounded-full border border-solid border-white text-white uppercase px-5 py-2 font-semibold drop-shadow-[0px_4px_5px_rgba(0,0,0,0.25)] whitespace-nowrap"
                            onClick={() => {
                                getRewardPhubrewardPool(props.phubrewardpooladdress);
                            }}
                        >
                            Claim
                        </button>
                    </>
                </div>
            )}
        </>
    );
};

export default Harvest;
